import React, { Fragment, useRef } from 'react';
import { useCompleteOnScreen } from '../../../utils/hooks';

import Marked from '../../Marked';
import { AnimatedDots } from '../../Dots';
import './index.css';
import zebraWork from '../../../img/zebra-work.svg';

export default ({
  title,
  body,
  name,
  role,
  quote,
  showQuoteSection,
  showThirdBlock
}) => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);

  return (
    (showThirdBlock || showQuoteSection) && (
      <section className="case-study-quote secondary-padding">
        <img src={zebraWork} alt="Background" />
        {showThirdBlock && (
          <div className={showQuoteSection ? 'margin' : ''}>
            <p className="c-title">{title}</p>
            <AnimatedDots completeOnScreen={completeOnScreen} />
            <div ref={ref}>
              <Marked 
                className="body"
                content={body}
              />
            </div>
          </div>
        )}
        {showQuoteSection && (
          <Fragment>
            <p className="name">{name}</p>
            <p className="position">{role}</p>
            <div className="quote">{quote}</div>
          </Fragment>
        )}
      </section>
    )
  );
};
