import React, { useEffect, useRef } from 'react';
import { useCompleteOnScreen } from '../../utils/hooks';

import { AnimatedDots } from '../Dots';
import './index.css';
import background from '../../img/zebra-large.svg';
import denver from '../../img/denver-icon.svg';
import portland from '../../img/portland-icon.svg';

export default () => {
  const ref = useRef();
  const isCompleteOnScreen = useCompleteOnScreen(ref);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7247359',
          formId: '9756f154-129c-41fc-9c54-54dcb89fda60',
          target: '#hubspotForm'
        });
      }
    });
  }, []);

  return (
    <section className="form secondary-padding">
      <img
        className="background"
        src={background}
        alt="Background"
      />
      <div ref={ref} className="left">
        <div id="hubspotForm"></div>
      </div>
      <div className="right">
        <div className="top">
          <p className="large-content">Let’s Make Something Awesome</p>
          <AnimatedDots completeOnScreen={isCompleteOnScreen} />
        </div>
        <div className="locations">
          <div className="is-flex-direction-column">
            <img src={denver} alt="Denver" />
            <p>
              <strong>Denver</strong>
            </p>
            <a
              href="https://www.google.com/maps/place/Devetry/@39.770774,-104.9704646,15z/data=!4m5!3m4!1s0x0:0x78f6ae4956e35462!8m2!3d39.7709275!4d-104.9706682"
              target="_blank"
              rel="noreferrer"
            >
              3858 Walnut St <br></br> Denver, CO 80205
            </a>
          </div>
          <div className="is-flex-direction-column">
            <img
              src={portland}
              alt="Portland"
            />
            <p>
              <strong>Portland</strong>
            </p>
            <a 
              href="https://www.google.com/maps/place/Industrious+Pioneer+Square/@45.5183169,-122.6815708,17z/data=!3m1!4b1!4m5!3m4!1s0x54950b5ccefae4b9:0x6774fc2d76ad26e5!8m2!3d45.51835!4d-122.6793692"
              target="_blank"
              rel="noreferrer"
            >
              811 SW 6th Ave Suite 1000 <br></br> Portland, OR 97204
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
