import React from 'react';

import Slider from '../../Slider';
import Button from '../../Button';
import Marked from '../../Marked';
import './index.css';
import orangeEllipse from '../../../img/orange-ellipse.svg';

export default ({
  images,
  orangeCircleTitle,
  orangeCircleBody,
  showSnippet,
  orangeCircleCTAText,
  orangeCircleCTALink,
  showSnippetCTA,
  sliderSectionTitle,
  sliderSectionBody,
  showSliderSection
}) => {
  return (
    <section className="work-study-slider">
      {showSliderSection && (
        <div className="top secondary-padding">
          <p className="c-title">{sliderSectionTitle}</p>
          <Marked 
            className="text"
            content={sliderSectionBody}
          />
        </div>
      )}
      <Slider images={images} />
      {showSnippet && (
        <div className="bottom secondary-padding">
          <img src={orangeEllipse} alt="Ellipse" />
          <p className="c-title">{orangeCircleTitle}</p>
          <p className="c-subtitle">{orangeCircleBody}</p>
          {showSnippetCTA && (
            <a href={orangeCircleCTALink} target="_blank">
              <Button text={orangeCircleCTAText} />
            </a>
          )}
        </div>
      )}
    </section>
  );
};
