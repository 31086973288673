import React from 'react';

import Marked from '../../Marked';
import './index.css';
import dots1 from '../../../img/case-study-dots-1.svg';
import dots2 from '../../../img/case-study-dots-2.svg';

const numbersBackgroundColor = ['#3EE5B8', '#EA6161', '#2AAAC1'];

export default ({ title, firstBody, subheading, secondBody, numbers }) => {
  //format numbers props into new array that includes the background color and always has 3 entries. 
  var numbersFormatted = [];
  if( numbers && numbers.length > 0){
   for(let i=0; i<3; i++){
      if(numbers[i] !== undefined){
        numbersFormatted.push({"number": numbers[i].number, "text": numbers[i].text, "color": numbersBackgroundColor[i % numbersBackgroundColor.length]})
      }
      else{
        numbersFormatted.push({"number": "", "text": "", "color": ""});
      }
    }
  }

  return (
    <section className="case-study-numbers secondary-padding">
      <img
        className="left-dots"
        src={dots1}
        alt="Dots"
      />
      <img
        className="right-dots"
        src={dots2}
        alt="Dots"
      />
      {title && <Marked className="main" content={title} />}
      <div className="numbers-container">
        {numbers &&
          numbersFormatted.map(({ number, text, color }, index) => (
            <div key={index} className="number-container">
              <div
                className="top"
                style={{
                  backgroundColor:
                    color
                }}
              />
              <p className="c-number">{number}</p>
              <p className="description">{text}</p>
            </div>
          ))}
      </div>
      {firstBody && <Marked className="text" content={firstBody} />}
      {subheading && (
        <p className="subheading" content={firstBody}>
          {subheading}
        </p>
      )}
      {secondBody && <Marked className="text" content={secondBody} />}
    </section>
  );
};
