import React, { Fragment, useState, useRef } from 'react';
import Img from 'gatsby-image';
import { useCompleteOnScreen } from '../../../utils/hooks';
import Marked from '../../Marked'

import { AnimatedDots } from '../../Dots';
import './index.css';
import dots1 from '../../../img/case-study-dots-1.svg';
import dots2 from '../../../img/case-study-dots-2.svg';

export default ({ items }) => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);
  const [currentTab, setCurrentTab] = useState(0);

  const content = () =>
    items[currentTab].showVideo
      ? items[currentTab].videoUrl && (
          <div className="video-wrapper">
            <iframe width="400" height="225" src={items[currentTab].videoUrl} controls autoPlay />
          </div>
        )
      : !!items[currentTab].image.src.childImageSharp ?
        <Img
          fluid={items[currentTab].image.src.childImageSharp.fluid}
          alt={items[currentTab].image.alt}
        />
        : <img src={items[currentTab].image.src.publicURL} alt={items[currentTab].image.alt}></img>;

  return (
    <Fragment>
      <section ref={ref} className="case-study-tabs secondary-padding">
        <img
          className="left-dots"
          src={dots1}
          alt="Dots"
        />
        <img
          className="right-dots"
          src={dots2}
          alt="Dots"
        />
        {items.length > 1 && (
          <div className="tabs-container">
            {items.map(({ stepTitle }, index) => (
              <div
                key={index}
                onClick={() => setCurrentTab(index)}
                className={`tab-container ${
                  currentTab === index ? 'active' : ''
                }`}
              >
                <div className="c-number">{`0${index + 1}`}</div>
                <p>{stepTitle}</p>
              </div>
            ))}
          </div>
        )}
        {items.length === 1 && (
          <div className="single-top">
            <p className="c-title">{items[currentTab].contentTitle}</p>
            <AnimatedDots completeOnScreen={completeOnScreen} />
          </div>
        )}
        <div className={`body ${items.length === 1 ? 'single' : ''}`}>
          <div className="left">
            <div className="tablet-container">
              <div className="right-tablet root-padding">{content()}</div>
              {items.length !== 1 && (
                <p className="c-title">{items[currentTab].contentTitle}</p>
              )}
              <p className="content"> <Marked content={items[currentTab].content} /> </p>
            </div>
          </div>
          <div className="right">{content()}</div>
        </div>
      </section>
      <section className="case-study-tabs-mobile secondary-padding">
        {items.map(
          ({ contentTitle, content, showVideo, image, videoUrl }, index) => (
            <div key={index} className="step">
              <p className="c-title">{contentTitle}</p>
              <p className="body">{content}</p>
              {showVideo
                ? videoUrl && (
                  <div className="video-wrapper">
                    <iframe width="400" height="225" src={items[currentTab].videoUrl} controls autoPlay />
                  </div>
                )
                : !!image.src.childImageSharp ?
                    <Img
                      fluid={image.src.childImageSharp.fluid}
                      alt={image.alt}
                    />
                    : <img src={image.src.publicURL} alt={image.alt}></img>
                  }
            </div>
          )
        )}
      </section>
    </Fragment>
  );
};
