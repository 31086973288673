import React from 'react';

import CTABanner from '../../CTABanner';
import BigCard from '../../workCards/BigWorkCard';
import './index.css';
import greenEllipse from '../../../img/green-ellipse.svg';

export default ({ content }) => {
  return (
    <section className="case-study-recommended">
      <CTABanner
        text="Work we think you’ll like…"
        buttonText="See all our projects"
        link="/work"
      />

      <div className="bottom secondary-padding">
        <BigCard caseStudy={content} />
      </div>
      <img
        className="green-ellipse"
        src={greenEllipse}
        alt="Ellipse"
      />
      <div className="grey-dot" />
    </section>
  );
};
