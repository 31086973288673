import React, { Fragment, useState } from 'react';
import Img from 'gatsby-image';

import './index.css';
import arrowLeft from '../../img/arrow-left-work.svg';

export default ({ images }) => {
  const [activeSlider, setActiveSlider] = useState(0);
  return (
    <Fragment>
      <div className="slider">
        <div className="controls">
          <div>
            <img
              onClick={() => setActiveSlider(activeSlider - 1)}
              className={activeSlider === 0 ? 'disabled' : ''}
              src={arrowLeft}
              alt="Left"
            />
            <div className="images">
              <p className="current-image">{`0${activeSlider + 1}`}</p>
              <p className="total-images">{`/0${images.length}`}</p>
            </div>
            <img
              onClick={() => setActiveSlider(activeSlider + 1)}
              className={activeSlider === images.length - 1 ? 'disabled' : ''}
              src={arrowLeft}
              alt="Right"
            />
          </div>
        </div>
        <Img
          fluid={images[activeSlider].src.childImageSharp.fluid}
          alt={images[activeSlider].alt}
        />
      </div>
      <div className="slider-mobile">
        {images.map(({ src, alt }, index) => (
          <Img key={index} fluid={src.childImageSharp.fluid} alt={alt} />
        ))}
      </div>
    </Fragment>
  );
};
