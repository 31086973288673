import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

// Sections
import Hero from '../../components/caseStudySections/Hero';
import Numbers from '../../components/caseStudySections/Numbers';
import Tabs from '../../components/caseStudySections/Tabs';
import Slider from '../../components/caseStudySections/Slider';
import Quote from '../../components/caseStudySections/Quote';
import Form from '../../components/Form';
import Recommended from '../../components/caseStudySections/Recommended';

import './index.css';

const CaseStudyPageTemplate = ({
  heroTitle,
  heroSubtitle,
  heroImage,
  firstBlockTitle,
  firstBlockFirstBody,
  firstBlockSubheading,
  firstBlockSecondBody,
  importantNumbers,
  tabs,
  heroLogo,
  showSnippet,
  showSnippetCTA,
  snippetTitle,
  snippetBody,
  snippetCTAText,
  snippetCTALink,
  sliderImages,
  thirdBlockTitle,
  thirdBlockBody,
  testimonialAttestantName,
  testimonialQuote,
  testimonialAttestantRole,
  showThirdBlock,
  secondBlockTitle,
  secondBlockBody,
  showSecondBlock,
  showTestimonialBlock,
  heroBackgroundImage,
  recommended
}) => {
  const pageTitle = heroTitle + ' | Featured Dev Work | Devetry';
  const pageDescription = '';
  return (
    <>
      <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="root-container case-study">
        <Hero
          title={heroTitle}
          subtitle={heroSubtitle}
          background={heroBackgroundImage}
          image={heroImage}
          logo={heroLogo}
        />
        <Numbers
          title={firstBlockTitle}
          numbers={importantNumbers}
          firstBody={firstBlockFirstBody}
          subheading={firstBlockSubheading}
          secondBody={firstBlockSecondBody}
        />
        <Tabs items={tabs} />
        <Slider
          images={sliderImages}
          sliderSectionTitle={secondBlockTitle}
          sliderSectionBody={secondBlockBody}
          showSliderSection={showSecondBlock}
          orangeCircleTitle={snippetTitle}
          orangeCircleBody={snippetBody}
          showSnippet={showSnippet}
          showSnippetCTA={showSnippetCTA}
          orangeCircleCTAText={snippetCTAText}
          orangeCircleCTALink={snippetCTALink}
        />
        <Quote
          showThirdBlock={showThirdBlock}
          title={thirdBlockTitle}
          body={thirdBlockBody}
          name={testimonialAttestantName}
          quote={testimonialQuote}
          showTestimonialBlock={showTestimonialBlock}
          role={testimonialAttestantRole}
          showQuoteSection={showTestimonialBlock}
        />
        <Form />
        <Recommended content={recommended} />
      </main>
    </>
  );
};

CaseStudyPageTemplate.propTypes = {};

const CaseStudyPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;
  const {
    frontmatter: recommendedFrontmatter,
    fields: recommendedFields
  } = fields.recommended;
  const recommended = { ...recommendedFrontmatter, ...recommendedFields };

  return (
    <CaseStudyPageTemplate
      heroTitle={frontmatter.heroTitle}
      heroSubtitle={frontmatter.heroSubtitle}
      heroBackgroundImage={frontmatter.heroBackgroundImage}
      heroImage={frontmatter.heroImage}
      heroLogo={frontmatter.heroLogo}
      firstBlockTitle={frontmatter.firstBlockTitle}
      firstBlockFirstBody={frontmatter.firstBlockFirstBody}
      firstBlockSubheading={frontmatter.firstBlockSubheading}
      firstBlockSecondBody={frontmatter.firstBlockSecondBody}
      importantNumbers={frontmatter.importantNumbers}
      sliderImages={frontmatter.sliderImages}
      tabs={frontmatter.tabs}
      snippetTitle={frontmatter.snippetTitle}
      snippetBody={frontmatter.snippetBody}
      snippetCTAText={frontmatter.snippetCTAText}
      showSnippet={frontmatter.showSnippet}
      showSnippetCTA={frontmatter.showSnippetCTA}
      snippetCTALink={frontmatter.snippetCTALink}
      thirdBlockTitle={frontmatter.thirdBlockTitle}
      thirdBlockBody={frontmatter.thirdBlockBody}
      showTestimonialBlock={frontmatter.showTestimonialBlock}
      testimonialAttestantName={frontmatter.testimonialAttestantName}
      testimonialQuote={frontmatter.testimonialQuote}
      testimonialAttestantRole={frontmatter.testimonialAttestantRole}
      showThirdBlock={frontmatter.showThirdBlock}
      secondBlockTitle={frontmatter.secondBlockTitle}
      secondBlockBody={frontmatter.secondBlockBody}
      showSecondBlock={frontmatter.showSecondBlock}
      recommended={recommended}
    />
  );
};

CaseStudyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default CaseStudyPage;

export const pageQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        recommended {
          fields {
            slug
          }
          frontmatter {
            cardTitle
            cardSubtitle
            tags
            cardImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            heroLogo {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
      frontmatter {
        heroTitle
        heroSubtitle
        heroBackgroundImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heroImage {
          src {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        heroLogo {
          src {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        firstBlockTitle
        firstBlockFirstBody
        firstBlockSubheading
        firstBlockSecondBody
        importantNumbers {
          number
          text
        }
        tabs {
          stepTitle
          contentTitle
          content
          showVideo
          videoUrl
          image {
            src {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            alt
          }
        }
        sliderImages {
          src {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1480) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        secondBlockTitle
        secondBlockBody
        showSecondBlock
        snippetTitle
        snippetBody
        showSnippet
        showSnippetCTA
        snippetCTAText
        snippetCTALink
        showThirdBlock
        thirdBlockTitle
        thirdBlockBody
        showTestimonialBlock
        testimonialAttestantName
        testimonialQuote
        testimonialAttestantRole
      }
    }
  }
`;
