import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import Dots from '../../Dots';
import './index.css';

export default ({ title, subtitle, image, logo, background }) => {
  return (
    <BackgroundImage
      className="case-study-background"
      fluid={background.childImageSharp.fluid}
    >
      <section
        className="case-study-hero"
        style={{
          background: 'linear-gradient(179deg, #475a64f4 0%, #475a64cc 100%)'
        }}
      >
        <div className="content root-padding">
          <Dots amount={6} />
          <p className="main">{title}</p>
          <div className="bottom">
            <p className="sub-title">{subtitle}</p>
          </div>
        </div>
        <div className="right">
          <Img
            className="hero-image"
            fluid={image.src.childImageSharp.fluid}
            alt={image.alt}
          />
        </div>
        <div className="hero-footer root-padding">
          <Img
            className="footer-image"
            fluid={logo.src.childImageSharp.fluid}
            alt={logo.alt}
          />
        </div>
      </section>
    </BackgroundImage>
  );
};
